<template>
  <div style="max-height: 300px; overflow: auto">
    <v-simple-table
      v-if="!loading"
      dense
      fixed-header
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(expandedHeader, index) in expandedHeaders"
              :key="index"
              class="text-left"
              :width="expandedHeader.width"
            >
              {{ expandedHeader.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(log) in logs"
            :key="log.rowKey"
          >
            <td
              v-for="(expandedHeader) in expandedHeaders"
              :key="`${log.partitionKey}-${expandedHeader.value}`"
              :width="expandedHeader.width"
            >
              <span v-if="expandedHeader.value === 'createdOn'">
                {{ log[expandedHeader.value] | displayDateTimeFormat }}
              </span>
              <span v-else>
                {{ log[expandedHeader.value] }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-skeleton-loader
      v-else
      type="table"
    />
  </div>
</template>

<script>
import { userService } from '@/shared/services'
import moment from 'moment'

export default {
  name: 'UserHistory',

  props: {
    userId: {
      type: String,
      default: null
    }
    // loading: {
    //   type: Boolean,
    //   default: false
    // }
  },

  data () {
    return {
      loading: false,
      logs: [],
      expandedHeaders: [
        {
          text: 'Created By',
          value: 'createdByName',
          width: '200'
        },
        {
          text: 'Date',
          value: 'createdOn',
          width: '200'
        },
        {
          text: 'Topic',
          value: 'topic',
          width: '500'
        },
        {
          text: 'Details',
          value: 'topicDetails',
          width: '1000'
        }
      ]
    }
  },

  computed: {

  },

  created () {
    this.loadLogs()
  },

  methods: {
    dateLocal (utc) {
      if (!utc) return null
      return moment(this.$_global_parseAsUtc(utc)).local().format()
    },
    async loadLogs () {
      this.loading = true
      var resp = await userService.userHistory(this.userId)
      this.logs = resp.map(x => (
        {
          ...x,
          createdOn: this.dateLocal(x.createdOn)
        }
      ))
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
