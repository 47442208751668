var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-height": "300px", overflow: "auto" } },
    [
      !_vm.loading
        ? _c("v-simple-table", {
            attrs: { dense: "", "fixed-header": "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("thead", [
                        _c(
                          "tr",
                          _vm._l(_vm.expandedHeaders, function(
                            expandedHeader,
                            index
                          ) {
                            return _c(
                              "th",
                              {
                                key: index,
                                staticClass: "text-left",
                                attrs: { width: expandedHeader.width }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(expandedHeader.text) +
                                    "\n          "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.logs, function(log) {
                          return _c(
                            "tr",
                            { key: log.rowKey },
                            _vm._l(_vm.expandedHeaders, function(
                              expandedHeader
                            ) {
                              return _c(
                                "td",
                                {
                                  key:
                                    log.partitionKey +
                                    "-" +
                                    expandedHeader.value,
                                  attrs: { width: expandedHeader.width }
                                },
                                [
                                  expandedHeader.value === "createdOn"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("displayDateTimeFormat")(
                                                log[expandedHeader.value]
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(log[expandedHeader.value]) +
                                            "\n            "
                                        )
                                      ])
                                ]
                              )
                            }),
                            0
                          )
                        }),
                        0
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              654762765
            )
          })
        : _c("v-skeleton-loader", { attrs: { type: "table" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }